@import './toasters.css';
@import './buttons.css';
@import './swiper-override.css';

@layer base {
  @font-face {
    font-family: 'Oakes Grotesk';
    font-display: auto;
    src: url(/fonts/oakes-grotesk-light.eot);
    src: url(/fonts/oakes-grotesk-light.woff2) format('woff2'), url(/fonts/oakes-grotesk-light.woff) format('woff'), url(/fonts/oakes-grotesk-light.ttf) format('truetype'),
      url(/fonts/oakes-grotesk-light.svg#youworkforthem) format('svg');
    font-weight: 300;
  }

  @font-face {
    font-family: 'Oakes Grotesk';
    font-display: auto;
    src: url(/fonts/oakes-grotesk-medium.eot);
    src: url(/fonts/oakes-grotesk-medium.woff2) format('woff2'), url(/fonts/oakes-grotesk-medium.woff) format('woff'), url(/fonts/oakes-grotesk-medium.ttf) format('truetype'),
      url(/fonts/oakes-grotesk-medium.svg#youworkforthem) format('svg');
    font-weight: normal;
  }

  @font-face {
    font-family: 'Oakes Grotesk';
    font-display: auto;
    src: url(/fonts/oakes-grotesk-bold.eot);
    src: url(/fonts/oakes-grotesk-bold.woff2) format('woff2'), url(/fonts/oakes-grotesk-bold.woff) format('woff'), url(/fonts/oakes-grotesk-bold.ttf) format('truetype'),
      url(/fonts/oakes-grotesk-bold.svg#youworkforthem) format('svg');
    font-weight: bold;
  }
}

.form-input {
  @apply font-thin;
}

input[type='date'].form-input {
  height: 42px;
}

select.form-input {
  height: 42px;
  background: url(data:image/svg+xml;base64,PHN2ZyBpZD0iTGF5ZXJfMSIgZGF0YS1uYW1lPSJMYXllciAxIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA0Ljk1IDEwIj48ZGVmcz48c3R5bGU+LmNscy0xe2ZpbGw6I2ZmZjt9LmNscy0ye2ZpbGw6IzQ0NDt9PC9zdHlsZT48L2RlZnM+PHRpdGxlPmFycm93czwvdGl0bGU+PHJlY3QgY2xhc3M9ImNscy0xIiB3aWR0aD0iNC45NSIgaGVpZ2h0PSIxMCIvPjxwb2x5Z29uIGNsYXNzPSJjbHMtMiIgcG9pbnRzPSIxLjQxIDQuNjcgMi40OCAzLjE4IDMuNTQgNC42NyAxLjQxIDQuNjciLz48cG9seWdvbiBjbGFzcz0iY2xzLTIiIHBvaW50cz0iMy41NCA1LjMzIDIuNDggNi44MiAxLjQxIDUuMzMgMy41NCA1LjMzIi8+PC9zdmc+)
    no-repeat 98% 50%;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
}

.field-with-error {
  input:focus-within ~ label,
  select:focus-within ~ label,
  textarea:focus-within ~ label {
    @apply duration-300 text-red-500 !important;
  }
}

input:focus-within ~ label,
input:not(:placeholder-shown) ~ label,
select:focus-within ~ label,
select:not([value='']):valid ~ label,
textarea:focus-within ~ label,
textarea:not(:placeholder-shown) ~ label {
  @apply transform scale-75 -translate-y-6  duration-300;
}

input:focus-within ~ label,
select:focus-within ~ label,
textarea:focus-within ~ label {
  @apply duration-300 transform scale-75 -translate-y-4 z-0 ml-3 px-1 py-0 text-wisteria;
}

.outline input:focus-within ~ label,
.outline input:not(:placeholder-shown) ~ label,
.outline select:focus-within ~ label,
.outline select:not([value='']) ~ label,
.outline textarea:not(:placeholder-shown) ~ label {
  @apply transform scale-75 -translate-y-4 z-0 ml-3 px-1 py-0;
}

.form-fieldset {
  @apply max-w-sm mx-auto rounded-lg p-6 space-y-10 bg-white shadow overflow-hidden sm:rounded-lg;
}

input[type='number']::-webkit-outer-spin-button,
input[type='number']::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type='number'] {
  -moz-appearance: textfield;
}

@keyframes arcspin {
  from {
    transform: rotateZ(0deg) scale(1);
  }

  50% {
    transform: rotateZ(540deg) scale(1);
    /* border-color: #0099ff; */
  }

  to {
    transform: rotateZ(1080deg) scale(1);
  }
}

.font-tiny {
  font-size: 0.5em;
}

.update-img {
  max-height: 12rem;
  max-width: 12rem;
}

.otp-input {
  @apply w-10 h-10 mr-1 text-lg border border-gray-300 !important;
}

.otp-container {
  @apply justify-center;
}

.navbar-logo {
  left: calc(50% - 1.5rem);
  top: 0.5rem;
}

.grecaptcha-badge {
  visibility: hidden;
}

.with-horizontal-line {
  @apply text-gray-700 text-center;
}

.with-horizontal-line::before,
.with-horizontal-line::after {
  @apply bg-gray-400 inline-block relative align-middle w-1/2;

  content: '';
  height: 1px;
}

.with-horizontal-line::before {
  right: 1em;
  margin-left: -50%;
}

.with-horizontal-line::after {
  left: 1em;
  margin-right: -50%;
}

.btn {
  letter-spacing: 0.87px;
}

.h2 {
  @apply text-xl font-bold mb-2;
}

.subtext {
  @apply font-light text-xs;
}

.df-progress-bar {
  width: calc(100% - 3em);
}

.header-h2 {
  @apply text-xl mb-4;
}

.dropdown-content {
  @apply p-2 shadow-lg menu bg-base-100 w-52 z-50 space-y-2 text-sm;
}

.dropdown-content > li {
  @apply py-2 hover:bg-gray-300;
}

.horizontal-line {
  @apply w-full h-px bg-gray-400 my-4;
}

.select-cancel-months {
  background: url(data:image/svg+xml;base64,PHN2ZyBpZD0iTGF5ZXJfMSIgZGF0YS1uYW1lPSJMYXllciAxIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA0Ljk1IDEwIj48ZGVmcz48c3R5bGU+LmNscy0xe2ZpbGw6I2ZmZjt9LmNscy0ye2ZpbGw6IzQ0NDt9PC9zdHlsZT48L2RlZnM+PHRpdGxlPmFycm93czwvdGl0bGU+PHJlY3QgY2xhc3M9ImNscy0xIiB3aWR0aD0iNC45NSIgaGVpZ2h0PSIxMCIvPjxwb2x5Z29uIGNsYXNzPSJjbHMtMiIgcG9pbnRzPSIxLjQxIDQuNjcgMi40OCAzLjE4IDMuNTQgNC42NyAxLjQxIDQuNjciLz48cG9seWdvbiBjbGFzcz0iY2xzLTIiIHBvaW50cz0iMy41NCA1LjMzIDIuNDggNi44MiAxLjQxIDUuMzMgMy41NCA1LjMzIi8+PC9zdmc+)
    no-repeat 98% 50%;
}

html,
body {
  width: 100vw;
  max-width: 100vw;
}

.dropdown-btn-with-icon {
  @apply text-xl inline-flex justify-center w-full border border-black shadow-sm px-2 py-1 bg-white font-medium text-black hover:bg-gray-50 focus:outline-none;
}

.dropdown-btn-transparent {
  @apply border-white text-white bg-transparent shadow-none hover:bg-transparent;
}

.dropdown-items {
  @apply z-50 origin-top-right absolute right-0 mt-2 w-56 shadow-lg bg-white divide-y divide-gray-100 focus:outline-none text-black font-thin border border-gray-300;
}

.dropdown-item {
  @apply text-gray-800 block px-4 py-2 text-sm;
}

.dropdown-item.active {
  @apply bg-gray-100 text-black;
}

.centered-v-h {
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
