.swiper-container-horizontal > .swiper-pagination-bullets {
  bottom: -5px !important;
}

.df-photos .swiper-pagination {
  left: auto !important;
  bottom: 40px !important;
  width: auto !important;
  right: 15px;
}

.swiper-pagination-bullet {
  @apply bg-gray-500 w-3 h-3 border-black border-solid border !important;
}
