/* .btn {
  @apply inline-flex items-center px-8   py-6 border-2 border-transparent font-medium shadow-sm focus:outline-none justify-center uppercase transition-all duration-300 ease-in-out;

  font-size: 13px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.87px;
}

.btn.btn__small {
  @apply px-2.5 py-1.5 text-xs;
}

.btn.btnprimary {
  @apply text-white bg-wisteria hover:bg-white active:bg-wisteria-light disabled:bg-wisteria-light hover:text-wisteria hover:border-wisteria disabled:text-white;
}

.btn.btn__secondary {
  @apply text-wisteria bg-white border-wisteria hover:bg-wisteria-light active:bg-wisteria-light disabled:bg-wisteria-light disabled:text-white hover:text-white;
}

.btn.btn__danger {
  @apply text-red-500 bg-white border-red-500 hover:bg-red-100 active:bg-red-100 disabled:bg-red-100;
} */

.btn {
  @apply rounded-none font-normal outline-none focus:outline-none;
}

button {
  @apply outline-none focus:outline-none;
}

.btn-primary[disabled] {
  @apply opacity-60 bg-wisteria text-white;
}
